import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../services/order-service/order-service.service';
import {ActivatedRoute} from '@angular/router';
import {SnapshotManagerService} from '../../services/snapshot-manager/snapshot-manager.service';
import {LocalStorage} from 'ngx-webstorage';
import {IDealerInformation} from '../../interfaces/information';
import {IOrderConfiguration, IOrderConfigurationData} from '../../interfaces/order';
import {ConfiguratorInterfaceService} from '../../services/configurator-interface/configurator-interface.service';
import {ModalOrderConfirmComponent} from '../../components/modal-order-confirm/modal-order-confirm.component';
import {ModalService} from '../../services/modal-service';
import {ModalBlueprintRequestComponent} from '../../components/modal-blueprint-request/modal-blueprint-request.component';

@Component({
    selector: 'app-page-offer-overview-component',
    templateUrl: './page-offer-overview.component.html',
    styleUrls: ['./page-offer-overview.component.scss']
})
export class PageOfferOverviewComponent implements OnInit {

    @LocalStorage() public readonly dealerInfo: IDealerInformation;

    public orderUUID: string;
    public order: IOrderConfiguration;
    public configurationData: IOrderConfigurationData;
    public loading: boolean;
    public hasError: boolean;
    public hasBackButton: boolean;
    public dealerCanEditOrder = false;
    public blueprintRequestAllowed = false;

    constructor(
        private route: ActivatedRoute,
        private orderService: OrderService,
        private modalService: ModalService,
        private snapShotManagerService: SnapshotManagerService,
        private configuratorInterface: ConfiguratorInterfaceService,
    ) {
    }

    ngOnInit() {
        this.orderUUID = this.route.snapshot.paramMap.get('uuid');

        // SNOEI-440
        this.hasBackButton = this.route.snapshot.queryParamMap.get('origin') !== 'mail';

        this.loading = true;
        this.hasError = false;
        this.orderService.getOrderConfiguration(this.orderUUID).then((orderConfiguration) => {
            this.order = orderConfiguration;
            this.configurationData = orderConfiguration.configuration;

            this.snapShotManagerService.loadConfigurationData(this.configurationData);

            this.configuratorInterface.viewerReady().then(() => {
                this.configuratorInterface.sendMessage({
                    action: 'setEditMode',
                    params: [false],
                });
            });

            this.orderService.dealerCanEditOrder(this.orderUUID).then((dealerCanEditOrder) => {
                this.dealerCanEditOrder = dealerCanEditOrder;
            });

            this.orderService.blueprintRequestCanBeMadeForOrder(this.orderUUID).then((blueprintRequestAllowed) => {
                this.blueprintRequestAllowed = blueprintRequestAllowed;
            });
        }).catch(() => {
            this.hasError = true;
        }).finally(() => {
            this.loading = false;
        });
    }

    public requestBlueprint() {
        this.modalService.open({
            component: ModalBlueprintRequestComponent,
            closeable: true,
            data: {
                order: this.order,
            },
        });
    }

    public getValue(reference, key, defaultValue?): any {
        let value = reference;
        const properties = key.split('.');
        if (properties.includes('template') && properties.includes('doors') && properties.includes('length')) {
            if (value && value.template && value.template.doors) {
                return this.getNumberOfDoors(reference);
            }
        }
        if (properties.includes('template') && properties.includes('windows') && properties.includes('length')) {
            if (value && value.template && value.template.windows) {
                return this.getNumberOfWindows(reference);
            }
        }
        properties.forEach((property) => {
            if (value && value[property]) {
                value = value[property];
            } else {
                value = null;
            }
        });
        return value ? value : defaultValue ? defaultValue : key;
    }

    public goBack(): void {
        window.history.back();
    }

    private getNumberOfDoors(reference): number {
        // SNOEI-949 - code needs to be reworked in the future
        const allDoors = reference.template.doors;
        const southDoors = allDoors.filter((door) => door.side === 'south');
        const northDoors = allDoors.filter((door) => door.side === 'north');
        const countSouthDoors = reference.template.disableFrontFacade === 1 ? false : true;
        const countNorthDoors = reference.template.disableBackFacade === 1 ? false : true;
        return allDoors.length - (countSouthDoors ? 0 : southDoors.length) - (countNorthDoors ? 0 : northDoors.length);
    }

    private getNumberOfWindows(reference): number {
        // SNOEI-949 - code needs to be reworked in the future
        const allWindows = reference.template.windows;
        const southWindows = allWindows.filter((window) => window.side === 'south');
        const northWindows = allWindows.filter((window) => window.side === 'north');
        const countSouthWindows = reference.template.disableFrontFacade === 1 ? false : true;
        const countNorthWindows = reference.template.disableBackFacade === 1 ? false : true;
        return allWindows.length - (countSouthWindows ? 0 : southWindows.length) - (countNorthWindows ? 0 : northWindows.length);
    }

}
